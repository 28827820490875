#top{
	select::-ms-expand {
		display: none;
	}
	.header{
		background-color: #030616;
	}
	.header-logo{
		margin-right: auto !important;
	}
	.entry-our_team{
		.hover-container{
			width: 100%;
		}
	}
	.footer--main-row{
		.flex_column{
			width: 20%;
			&:last-child{
				.ep-item-grid-icon{
					font-size: 25px;
				}
			}
		}
	}
}